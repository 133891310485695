import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

export const Header = () => {
  const imageData = useStaticQuery(pageQuery);
  return (
    <div
      style={{
        backgroundImage:
          'url(https://cdn.shopify.com/s/files/1/0504/5492/1404/products/il_fullxfull.2107320850_is8v.jpg)',
      }}
      className='bg-cover bg-center bg-no-repeat'
    >
      <div>
        <header className='py-32 text-center mb-12 bg-gray-700 bg-opacity-50'>
          <div>
            <h1 className='text-white text-6xl mb-2'>Orange Willow Art</h1>
            <h2 className='text-white'>Prints and Printables from our creative home to yours!</h2>
          </div>
        </header>
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query {
    allShopifyProduct(
      filter: {
        images: {
          elemMatch: {
            localFile: {
              url: {
                eq: "https://cdn.shopify.com/s/files/1/0504/5492/1404/products/il_fullxfull.2107320850_is8v.jpg"
              }
            }
          }
        }
      }
    ) {
      nodes {
        images {
          localFile {
            url
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
