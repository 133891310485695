import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function ProductElement({ product }) {
  return (
    <div>
      <li className='m-5 w-64 flex flex-col  justify-between transition-all ease-in-out duration-200 transform hover:-translate-y-2'>
        <a href={`products/${product.handle}`}>
          <GatsbyImage
            className='li-product-image w-full'
            image={product.images[0].localFile.childImageSharp.gatsbyImageData}
            alt=''
            placeholder='blurred'
          />
          <div className='pt-6 px-4'>
            <p className='font-bold mb-1'>{product.title}</p>
            <p className='font-thin'>${product.variants[0].price}</p>
          </div>
        </a>
      </li>
    </div>
  );
}
